/*

Array of object

Top level object can be:
1. Header
2. Group (Group can have navItems as children)
3. navItem

* Supported Options

/--- Header ---/

header

/--- nav Grp ---/

title
icon (if it's on top level)
tag
tagVariant
children

/--- nav Item ---/

icon (if it's on top level)
title
route: [route_obj/route_name] (I have to resolve name somehow from the route obj)
tag
tagVariant

*/

import adminAbaOnline from "./admin-aba-online";
import SpeakerAbaOnline from "./speaker-aba-online";

const userData = JSON.parse(localStorage.getItem("userData"));
console.log("🚀 ~ file: index.js ~ line 38 ~ userData", userData);
const Menu = userData
	? +userData.role == 1
		? adminAbaOnline
		: SpeakerAbaOnline
	: null;
// Array of sections
export default [...Menu];
