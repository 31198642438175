export default [
	{
		header: "Admin aba-online",
	},
	{
		title: "Configurations",
		icon: "SettingsIcon",
		children: [
			{
				title: "Général",
				route: "app-config-list",
			},
			{
				title: "Email",
				route: "app-mail-list",
			},
			{
				title: "Liste",
				route: "app-list-object",
			},
		],
	},
	{
		title: "Administration",
		icon: "UserIcon",
		children: [
			{
				title: "Speakers",
				route: "app-speaker-list",
			},
			{
				title: "Webinars",
				route: "app-webinar-list",
			},
			{
				title: "Surveys",
				route: "app-survey-list",
			},
			{
				title: "Quiz",
				route: "app-quiz-list",
			},
		],
	},
];
