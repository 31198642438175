export default [
	{
		header: "Admin aba-online",
	},

	{
		title: "Webinars",
		route: "app-webinar-list",
	},
	{
		title: "Quizz",
		route: "app-quiz-list",
	},
	{
		title: "My profile",
		route: "my-profil",
	},
];
