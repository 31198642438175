<template>
	<b-nav-item-dropdown
		right
		toggle-class="d-flex align-items-center dropdown-user-link"
		class="dropdown-user"
	>
		<template #button-content>
			<div class="d-sm-flex d-none user-nav">
				<p class="user-name font-weight-bolder mb-0">
					{{ userData.firstname }} {{ userData.lastname }}
				</p>
				<span class="user-status"></span>
			</div>
			<b-avatar
				size="40"
				:src="userData.avatar"
				variant="light-primary"
				badge
				class="badge-minimal"
				badge-variant="success"
			>
			</b-avatar>
		</template>

		<b-dropdown-item link-class="d-flex align-items-center" @click="logout">
			<feather-icon size="16" icon="LogOutIcon" class="mr-50" />
			<span>Logout</span>
		</b-dropdown-item></b-nav-item-dropdown
	>
</template>

<script>
import { BNavItemDropdown, BDropdownItem, BAvatar } from "bootstrap-vue";
import { avatarText } from "@core/utils/filter";
import VueCookie from "vue-cookies";
import { initialAbility } from "@/libs/acl/config";

export default {
	components: {
		BNavItemDropdown,
		BDropdownItem,

		BAvatar,
	},
	name: "NavBarreUser",
	data() {
		return {
			userData: JSON.parse(localStorage.getItem("userData")),
			avatarText,
		};
	},
	// computed: {
	//     //...mapState(['user']),
	//     //console.log("test mapstate", user)}
	//   // user () {
	//   //   // Récupère le getter user du module user
	//   //   //return this.$store.getters['user/user']
	//   //   //console.log("test user", user)
	//   // }
	//   },
	methods: {
		logout() {
			// Remove userData from localStorage
			// ? You just removed token from localStorage. If you like, you can also make API call to backend to blacklist used token
			// localStorage.removeItem(useJwt.jwtConfig.storageTokenKeyName)
			// localStorage.removeItem(useJwt.jwtConfig.storageRefreshTokenKeyName)
			VueCookie.remove("accessToken");
			// Remove userData from localStorage
			localStorage.removeItem("userData");

			localStorage.removeItem("accessToken");

			// Reset ability
			this.$ability.update(initialAbility);

			// Redirect to login page
			this.$router.push({ name: "authentification" });
		},
	},
};
</script>
